import React, { useEffect, useState, useRef } from "react";
import List from "./list";
import Utils from "../../utility";
import { getClaimList } from "../../services/zbrainConnectorService";
import { keyConstants } from "../../constants";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const ClaimListComponent = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const pageSize = 50;
  const [totalDoc, setTotalDoc] = useState(0);
  const totalPages = Math.ceil(totalDoc / pageSize);
  const [currentPage, setCurrentPage] = useState(
    Number(queryParams.get("page")) || 1
  );
  const [debounce, setDebouncedSearch] = useState("");
  const topRef = useRef(null);
  const [resultFilter, setResultFilter] = useState(
    queryParams.get("result") || "All"
  );
  const [feedbackFilter, setFeedbackFilter] = useState(
    queryParams.get("feedback") || "All"
  );
  const [sortOrder, setSortOrder] = useState(queryParams.get("sort") || "desc");
  const [date, setDate] = useState({
    startDate: queryParams.get("toDate") || null,
    endDate: queryParams.get("fromDate") || null
  });
  const [selectTime, setSelectTime] = useState(
    queryParams.get("selectTime") || "All"
  );
  const [processedDate, setProcessedDate] = useState({
    startDate: queryParams.get("processedOnTo") || null,
    endDate: queryParams.get("processedOnFrom") || null
  });
  const [selectProcessedTime, setSelectProcessedTime] = useState(
    queryParams.get("processedTime") || "All"
  );
  const [versions, setVerisons] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(
    queryParams.get("v") || "All"
  );

  const getDateRange = (label) => {
    const now = new Date();
    switch (label) {
      case "Last 14 days":
        return {
          startDate: Utils.formatDateYear(
            new Date(now - 13 * 24 * 60 * 60 * 1000)
          ),
          endDate: Utils.formatDateYear(now)
        };
      case "Previous month": {
        const firstDayOfMonth = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          1
        );
        const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 0);
        return {
          startDate: Utils.formatDateYear(firstDayOfMonth),
          endDate: Utils.formatDateYear(lastDayOfMonth)
        };
      }

      default:
        return null;
    }
  };

  const getDateRangeProcessed = (label) => {
    const now = new Date();

    const startOfDayUTC = (date) => {
      return Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0,
        0,
        0
      );
    };

    const endOfDayUTC = (date) => {
      return Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        23,
        59,
        59,
        999
      );
    };

    switch (label) {
      case "Last 7 days":
        return {
          startDate: startOfDayUTC(
            new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000)
          ),
          endDate: endOfDayUTC(now)
        };
      case "Last 14 days":
        return {
          startDate: startOfDayUTC(
            new Date(now.getTime() - 13 * 24 * 60 * 60 * 1000)
          ),
          endDate: endOfDayUTC(now)
        };
      case "Previous month": {
        const firstDayOfMonth = new Date(
          now.getUTCFullYear(),
          now.getUTCMonth() - 1,
          1
        );
        const lastDayOfMonth = new Date(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          0
        );
        return {
          startDate: startOfDayUTC(firstDayOfMonth),
          endDate: endOfDayUTC(lastDayOfMonth)
        };
      }
      default:
        return null;
    }
  };

  const getClaims = async () => {
    try {
      let requesData = {
        claimNumber: debounce,
        limit: pageSize,
        page: currentPage,
        sortBy: sortOrder
      };
      const checkName = queryParams.get("checkName");
      const checkFeedback = queryParams.get("checkFeedback");
      const elementName = queryParams.get("elementName");
      const elementFeedback = queryParams.get("elementFeedback");
      if (selectedVersion !== "All") {
        requesData.version = Number(selectedVersion.slice(1));
      }
      if (queryParams.get("withoutFeedback")) {
        requesData.withoutFeedback = true;
      }
      if (checkName) {
        requesData.checkName = checkName;
      }
      if (checkFeedback) {
        requesData.checkFeedback = checkFeedback;
      }
      if (elementName) {
        requesData.elementName = elementName;
      }
      if (elementFeedback) {
        requesData.elementFeedback = elementFeedback;
      }
      let highestVersionArray = [];
      if (selectTime !== "Custom" && selectTime !== "All") {
        const { startDate, endDate } = getDateRange(selectTime);
        requesData.fromDate = startDate;
        requesData.toDate = endDate;
      }
      if (date.startDate && date.endDate && selectTime === "Custom") {
        requesData.fromDate = date.startDate;
        requesData.toDate = date.endDate;
      }
      if (selectProcessedTime !== "Custom" && selectProcessedTime !== "All") {
        const { startDate, endDate } =
          getDateRangeProcessed(selectProcessedTime);
        requesData.processedOnFrom = startDate;
        requesData.processedOnTo = endDate;
      }
      if (
        processedDate.startDate &&
        processedDate.endDate &&
        selectProcessedTime === "Custom"
      ) {
        const startDateObject = new Date(
          processedDate.startDate.split("-").reverse().join("-")
        );
        const startUTCDate = Date.UTC(
          startDateObject.getUTCFullYear(),
          startDateObject.getUTCMonth(),
          startDateObject.getUTCDate(),
          0,
          0,
          0,
          0
        );
        requesData.processedOnFrom = startUTCDate;
        let endDateObject = new Date(
          processedDate.endDate.split("-").reverse().join("-")
        );
        const endUTCDate = Date.UTC(
          endDateObject.getUTCFullYear(),
          endDateObject.getUTCMonth(),
          endDateObject.getUTCDate(),
          23,
          59,
          59,
          999
        );

        requesData.processedOnTo = endUTCDate;
      }

      if (feedbackFilter !== "All") {
        requesData.feedback =
          feedbackFilter === "Correct"
            ? keyConstants.CORRECT
            : feedbackFilter === "Incorrect AI response"
              ? keyConstants.INCORRECT_AI_RESPONSE
              : keyConstants.INCORRECT_DATA_IMAGE;
      }
      if (resultFilter !== "All") {
        requesData.comparisonResult = resultFilter;
      }
      setLoading(true);
      const [error, response] = await Utils.parseResponse(
        getClaimList(requesData)
      );

      if (error || !response) {
        if (error?.response?.status === 500) {
          toast.error("Internal server error");
        } else {
          toast.error(error?.response?.data?.message);
        }
        return;
      }
      for (let i = 1; i <= response.highestVersion; i++) {
        highestVersionArray.push(`v${i}`);
      }
      setVerisons(highestVersionArray);
      setTotalDoc(response.totalDocuments);

      setList(response?.claims);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      scrollToTop();
    }
  };
  const handleFirstPage = () => {
    handlePageChange(1);
    scrollToTop();
  };

  const handleLastPage = () => {
    handlePageChange(totalPages);
    scrollToTop();
  };

  const updateQueryParams = (key, value) => {
    if (value) {
      queryParams.set(key, value);
    } else {
      queryParams.delete(key);
    }
  };

  const updateDateParams = () => {
    if (
      date?.endDate !== null &&
      date?.startDate !== null &&
      selectTime === "Custom"
    ) {
      queryParams.set("toDate", date.startDate);
      queryParams.set("fromDate", date.endDate);
    } else {
      queryParams.delete("toDate");
      queryParams.delete("fromDate");
    }
  };
  const updateProcessedParams = () => {
    if (
      processedDate?.endDate !== null &&
      processedDate?.startDate !== null &&
      selectProcessedTime === "Custom"
    ) {
      queryParams.set("processedOnTo", processedDate.startDate);
      queryParams.set("processedOnFrom", processedDate.endDate);
    } else {
      queryParams.delete("processedOnTo");
      queryParams.delete("processedOnFrom");
    }
  };

  useEffect(() => {
    getClaims();
    updateQueryParams("result", resultFilter);
    updateQueryParams("page", currentPage);
    updateQueryParams("v", selectedVersion);
    updateQueryParams("feedback", feedbackFilter);
    updateDateParams();
    queryParams.set("selectTime", selectTime);
    updateProcessedParams();
    queryParams.set("processedTime", selectProcessedTime);
    queryParams.set("sort", sortOrder);
    navigate(
      {
        search: queryParams.toString()
      },
      { replace: true }
    );
    // eslint-disable-next-line
  }, [
    debounce,
    currentPage,
    resultFilter,
    feedbackFilter,
    selectTime,
    date,
    sortOrder,
    selectedVersion,
    selectProcessedTime,
    processedDate
  ]);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition && topRef.current) {
      topRef.current.scrollTo(0, parseInt(savedScrollPosition, 10));
    }
  });

  return (
    <div className="h-full">
      <List
        loading={loading}
        list={list}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleFirstPage={handleFirstPage}
        handleLastPage={handleLastPage}
        setDebouncedSearch={setDebouncedSearch}
        debounce={debounce}
        resultFilter={resultFilter}
        setResultFilter={setResultFilter}
        feedbackFilter={feedbackFilter}
        setFeedbackFilter={setFeedbackFilter}
        setDate={setDate}
        date={date}
        setSelectTime={setSelectTime}
        selectTime={selectTime}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
        topRef={topRef}
        setCurrentPage={setCurrentPage}
        versions={versions}
        setSelectedVersion={setSelectedVersion}
        selectedVersion={selectedVersion}
        processedDate={processedDate}
        setProcessedDate={setProcessedDate}
        selectProcessedTime={selectProcessedTime}
        setSelectProcessedTime={setSelectProcessedTime}
      />
    </div>
  );
};

export default ClaimListComponent;
