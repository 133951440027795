import React, { useState, useEffect } from "react";
import { keyConstants } from "../../../constants";

const FeedbackPopup = ({
  align = "left-0",
  setState,
  elementData,
  setElementData = () => {},
  handleFeedback,
  edit = false,
  handleUpdateFeedback,
  callUpdate = false,
  topAlign = "top-4",
  clearData = () => {},
  isForCorrect = false
}) => {
  const [editReason, setEditReason] = useState(false);
  useEffect(() => {
    if (isForCorrect) {
      setElementData((prev) => ({
        ...prev,
        feedback: keyConstants.CORRECT
      }));
    }
  }, []);
  return (
    <div
      className={`absolute ${topAlign} h-auto w-85 rounded-lg border border-grey-100 bg-white drop-shadow-sm ${align} z-50 p-2.5`}
    >
      <div className="flex w-full justify-between">
        <h1 className="font-InterSemiBold text-ft16-24 text-black-0">
          Tell us more
        </h1>
        <div className="flex gap-2.5">
          {edit && (
            <img
              onClick={() => setEditReason(!editReason)}
              src="/images/edit.svg"
              alt=""
              className="size-3 cursor-pointer"
            />
          )}
          <img
            onClick={() => {
              setState(false);
              setElementData((prev) => ({
                ...prev,
                feedback: "",
                comment: ""
              }));
              clearData();
            }}
            src="/images/cross.svg"
            alt=""
            className="size-3 cursor-pointer"
          />
        </div>
      </div>
      {!isForCorrect && (
        <div className="flex flex-wrap gap-3 pt-3">
          <div
            onClick={() => {
              if ((edit && editReason) || !edit)
                setElementData((prev) => ({
                  ...prev,
                  feedback: keyConstants.INCORRECT_AI_RESPONSE
                }));
            }}
            className={`rounded-lg  border font-InterRegular text-ft14-17 ${elementData?.feedback === keyConstants.INCORRECT_AI_RESPONSE ? "border-green-10 text-green-10" : "border-grey-30 text-black-0"} flex h-34px w-[48%] cursor-pointer items-center justify-center`}
          >
            <span>Incorrect AI response</span>
          </div>

          <div
            onClick={() => {
              if ((edit && editReason) || !edit)
                setElementData((prev) => ({
                  ...prev,
                  feedback: keyConstants.INCORRECT_DATA_IMAGE
                }));
            }}
            className={`rounded-lg border font-InterRegular text-ft14-17 ${elementData?.feedback === keyConstants.INCORRECT_DATA_IMAGE ? "border-green-10 text-green-10" : "border-grey-30 text-black-0"} flex h-34px w-[48%] cursor-pointer items-center justify-center text-black-0`}
          >
            <span>Incorrect data/image</span>
          </div>

          {edit && (
            <div
              onClick={() => {
                if ((edit && editReason) || !edit)
                  setElementData((prev) => ({
                    ...prev,
                    feedback: keyConstants.CORRECT
                  }));
              }}
              className={`rounded-lg border font-InterRegular text-ft14-17 ${elementData?.feedback === keyConstants.CORRECT ? "border-green-10 text-green-10" : "border-grey-30 text-black-0"} flex h-34px w-[48%] cursor-pointer items-center justify-center text-black-0`}
            >
              <span>Correct</span>
            </div>
          )}
        </div>
      )}
      <div className="pt-4.5">
        <textarea
          name=""
          id=""
          placeholder="Add comments (Optional)"
          value={elementData?.comment}
          onChange={(e) =>
            setElementData((prev) => ({ ...prev, comment: e.target.value }))
          }
          disabled={edit && !editReason}
          className="h-20 w-full resize-none rounded-lg border border-grey-100 p-2 font-InterRegular outline-none"
        />
      </div>

      {!edit && (
        <div className="flex w-full justify-end pt-3">
          <button
            disabled={!elementData?.feedback}
            onClick={edit || callUpdate ? handleUpdateFeedback : handleFeedback}
            className={`h-8 w-30 rounded-[4px] ${elementData?.feedback ? "bg-green-10" : "bg-green-10 bg-opacity-[.6]"} font-InterSemiBold text-ft15-18 text-offWhite-10`}
          >
            Submit
          </button>
        </div>
      )}

      {edit && editReason && (
        <div className="flex w-full justify-between pt-3">
          <button
            onClick={() => setState(false)}
            className="h-8 w-30 rounded-[4px] border border-grey-10 bg-white font-InterSemiBold text-ft15-18 text-black-20"
          >
            Cancel
          </button>

          <button
            disabled={!elementData?.feedback}
            onClick={edit || callUpdate ? handleUpdateFeedback : handleFeedback}
            className={`h-8 w-30 rounded-[4px] ${elementData?.feedback ? "bg-green-10" : "bg-green-10 bg-opacity-[.6]"} font-InterSemiBold text-ft15-18 text-offWhite-10`}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default FeedbackPopup;
